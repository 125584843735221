<template>
  <div class="header" :class="isMarginBottom ? 'margin-bottom-header' : ''">
    <div
        class="nav-header"
        :class="show == 'myCenter' ? 'nav-center-header' : ''"
    >
      <div class="container dfal jsb" :class="isBothSides?'isBothSides': ''">
        <div class="dfaj"> <!-- 加这么一层是为了适配isBothSides -->
          <div class="header-logo-img cp dfaj" style="margin-right: 15px"  @click="handleGotoHome">
            <img
                style="width: 170px; height: 48px"
                src="@/assets/img/shuishan_logo.png"
                v-if="show != 'myCenter'"
            />
            <img
                style="width: 163px; height: 42px"
                src="@/assets/img/center/center-logo.png"
                v-if="show == 'myCenter'"
            />
          </div>
          <div class="header-menu dfaj fs16">
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="show == 'indexDetail' ? 'item-menu-highlight fs16' : ''"
                  @click="handleGotoHome"
              >首页</span
              >
            </div>
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="show == 'courseDetail' ? 'item-menu-highlight fs16' : ''"
                  @click="hanldeGotoCourse"
              >水杉学堂</span
              >
            </div>
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="show == 'workShop' ? 'item-menu-highlight fs16' : ''"
                  @click="hanldeGotoQuestion"
              >水杉工坊</span
              >
            </div>
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="
                  show == 'calibrationField' ? 'item-menu-highlight fs16' : ''
                "
                  @click="handleGotoField"
              >水杉校场</span
              >
            </div>
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="show == 'codePark' ? 'item-menu-highlight fs16' : ''"
                  @click="handleGotoCodePark"
              >水杉码园</span
              >
            </div>
            <div class="item-menu cp dfaj" style="margin-right: 25px">
              <span
                  :class="show == 'channel' ? 'item-menu-highlight fs16' : ''"
                  @click="handleGotoChannel"
              >水杉频道</span
              >
            </div>
            <!--            <div class="item-menu cp dfaj" style="margin-right: 25px">-->
            <!--              <span-->
            <!--                :class="show == 'programDetail' ? 'item-menu-highlight fs16' : ''"-->
            <!--                @click="hanldeGotoProgram"-->
            <!--                >开源教育</span-->
            <!--              >-->
            <!--            </div>-->
            <!--            <div class="item-menu cp dfaj">-->
            <!--              <div-->
            <!--                :class="-->
            <!--                  show == 'firstClass'-->
            <!--                    ? 'dropdown-select dfaj item-menu-highlight'-->
            <!--                    : 'dropdown-select dfaj'-->
            <!--                "-->
            <!--                @click="handleClickFirst"-->
            <!--              >-->
            <!--                <img-->
            <!--                  class="item-menu-program-img"-->
            <!--                  src="../assets/img/index/first_back.png"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div style="display: flex; align-items: center; margin-left: -120px;">
            <div style="margin-right: 5px; white-space: nowrap;">
              <i class="el-icon-location" style="color: #13A81B;"></i>
              <span style="color: #13A81B; font-size: 15px">当前机构</span>
            </div>
            <el-select
                placeholder="切换"
                style="color: black; width: 12em"
                :disabled="!isLogin"
                @click.native="handleSelectClick"
                v-model="selectedCompany"
                emit-value="false"
                map-options="false"
                class="my-select"
            >
              <el-option
                  v-for="item in companyOptions"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="topbar-user dfaj">
          <login></login>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { portalV1URL } from "@/env";
import login from "../pages/Login.vue";
import {getToken} from "@/util/auth";
import { getCompanies, getCurCompany } from "@/api/company/company.js"
import {mapState} from "vuex";
export default {
  components: {
    login,
  },
  name: "nav-header",
  data() {
    return {
      show: "",
      carvalue: undefined,
      isBothSides: false,
      isMarginBottom: false,
      isLogin: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
        if (route.path == "/home") {
          this.show = "indexDetail";
        } else if (route.matched[0].path == "/mooc") {
          this.show = "courseDetail";
        } else if (route.matched[0].path == "/question") {
          this.show = "calibrationField";
        } else if (route.matched[0].path == "/center") {
          this.show = "myCenter";
        } else if (route.matched[0].path == "/codePark") {
          this.show = "codePark";
        } else if (route.matched[0].path == "/channel") {
          this.show = "channel"
        } else if (route.matched[0].path == "/program") {
          this.show = "programDetail";
        } else if (route.matched[0].path == "/work") {
          this.show = "workShop";
        }
        if (route.path == '/mooc/lessonDetails') {
          this.isBothSides = true
        } else {
          this.isBothSides = false
        }
        if (route.path == '/mooc/course/detail') {
          this.isMarginBottom = true;
        }else {
          this.isMarginBottom = false;
        }
      },
      immediate: true,
    },
    selectedCompany: {
      handler: async function (newV, oldVal) {
        // console.log('old:' + oldVal)
        // console.log('new:' + newV)
        await this.$store.dispatch('setSelectedCompany', newV)
        this.eventBus.$emit('emit-school-tag-list-refresh')  // School页面
        this.eventBus.$emit('emit-school-recommend-list-refresh')
        this.eventBus.$emit('emit-courselist-tag-list-refresh')  // CourseList页面
        this.eventBus.$emit('emit-courselist-recommend-list-refresh')
      }
    }
  },
  computed: {
    companyOptions() {
      return this.$store.state.companies; // 直接从 Vuex 读取
    },
    selectedCompany: {
      get() {
        return this.$store.state.selectedCompany; // 从 Vuex 获取 selectedCompany
      },
      set(value) {
        this.$store.dispatch('setSelectedCompany', value); // 通过 Vuex 更新状态
      }
    }
  },
  filters: {},
  mounted() {
    // 判断登录状态
    this.checkIsLogin()
    this.init();
  },
  methods: {
    init () {
      this.initCompany()
      this.loadCompanies()
    },
    async initCompany() {
      try {
        const res = await getCurCompany(this.isLogin); // 等待异步请求返回
        let companyName = '暂无'; // 默认值
        if (res.data && res.data.companyName) {
          companyName = res.data.companyName === '访客' ? '暂无' : res.data.companyName;
        }
        // 统一设置 Vuex 状态
        await this.$store.dispatch('setSelectedCompany', companyName);
      } catch (error) {
        console.error('获取当前公司失败:', error);
        await this.$store.dispatch('setSelectedCompany', '暂无');
      }
    },
    async loadCompanies() {
      try {
        const res = await getCompanies(); // 调用外部 API
        this.$store.dispatch('setCompanies', res.data); // 直接触发 Vuex 的 Action
      } catch (error) {
        console.error('加载公司数据失败:', error);
        this.$store.dispatch('setCompanies', []); // 传递空数组
      }
    },
    checkIsLogin() {
      this.isLogin = !(getToken() === undefined || getToken() === "");
      return this.isLogin
    },
    //跳转到首页
    handleGotoHome() {
      if (!this.isLogin) {

      }
      this.show = "indexDetail";
      this.$router.push({
        path: "/home",
      });
    },
    //跳转到水杉学堂
    hanldeGotoCourse() {
      this.show = "courseDetail";
      this.$router.push({
        path: "/mooc/school"
      });
    },
    //跳转到答题
    hanldeGotoQuestion() {
      this.show = "workShop";
      this.$router.push({
        path: "/work/workShop",
        query: {
          // edit:1
        },
      });
    },
    //跳转到水杉码园
    handleGotoCodePark() {
      this.show = "codePark";
      this.$router.push({
        path: "/codePark/codeParkDetail",
        query: {
          courseId: "1569130547979730945",
        },
      });
    },
    //跳转到开源计划
    // hanldeGotoProgram() {
    //   this.show = "programDetail";
    //   this.$router.push({
    //     path: "/program/programDetail",
    //     query: {
    //       courseId: "1569130547979730945",
    //     },
    //   });
    // },
    //跳转到水杉校场
    handleGotoField() {
      this.show = "calibrationField";
      this.$router.push({
        path: "/question/calibrationField",
        // query: {
        //   courseId: "1569130547979730945"
        // },
      });
    },
    //跳转到新生计算机第一课
    handleClickFirst() {
      window.open("http://10.0.40.14/cs101/index")
    },
    //跳转到水杉频道
    handleGotoChannel() {
      this.openChannel(this.isLogin);
    },
    openChannel(isLogin) {
      if (isLogin) {
        // 带着token跳转到shuishan.net.cn/oauth做认证，重定向到/channel页面
        window.open(portalV1URL + "/oauth?access_token=" + getToken() + "&redirect=channel", "_blank")
      } else {
        window.open(portalV1URL + "/channel")
      }
    },
    handleSelectClick () {
      if (!this.isLogin) {
        this.$nextTick(() => {
          alert('请登录后切换机构');
        })
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/mixin.scss";

.el-dialog__headerbtn {
  line-height: 0px;
  width: 10px;
  height: 0px;
}

.header {
  width: 100%;
  min-width: 1200px;
  &.margin-bottom-header {
    .container {
      height: 64px;
    }
  }
  .nav-header {
    width: 100%;
    min-width: 1200px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(22, 43, 111, 0.08);
    &.nav-center-header {
      background-color: #1a1a1c;
    }
    .container {
      position: relative;
      // margin-left: 235px;
      height: 74px;
      width: 1200px;
      &.isBothSides {
        width: 100%;
        min-width: 1246px;
        padding-left: 18px;
        box-sizing: border-box;
        justify-content: space-between;
      }

      .header-logo-img {
        // width: 163px;
        // height: 66px;
        margin-top: 2px;
        margin-right: 70px;
      }

      .header-menu {
        width: 760px;
        height: 74px;

        .item-menu {
          min-width: 32px;
          white-space: nowrap;
          color: #13a81b;
          height: 22px;
          line-height: 22px;
          padding-bottom: 13px;
          margin: 22px 40px 12px 0px;
          position: relative;
          color: #181717;
          letter-spacing: 0;
          font-weight: 600;

          .item-menu-highlight {
            font-family: PingFangSC-Regular;
            color: #13a81b;
            font-weight: 600;
          }
          .item-menu-highlight:after {
            content: "";
            width: 100%;
            height: 3px;
            border-radius: 2.5px;
            position: absolute;
            background: #13a81b;
            top: 100%;
            right: 0;
            margin-top: -1px;
            transition: all 0.5s;
          }
          span {
            font-weight: 400;
          }
          &:after {
            content: "";
            width: 0;
            height: 3px;
            border-radius: 2.5px;
            position: absolute;
            background: #13a81b;
            top: 100%;
            right: 50%;
            margin-top: -1px;
            transition: all 0.5s;
          }
          &:hover {
            color: #13a81b;
            span {
              font-weight: 600;
            }
          }
          &:hover:after {
            width: 100%;
            right: 0;
          }
          .dropdown-select {
            font-family: TRENDS;
            color: #00635c;
            letter-spacing: 0;
            background-image: -webkit-linear-gradient(
                    left,
                    #1ec43f 0%,
                    #0ca4ee 70%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            .item-menu-program-img {
              width: 129px;
              height: 15px;
            }
          }
        }
      }

      .topbar-user {
        margin-bottom: 2px;
        // margin: 0px 0px 0px 200px;
      }
    }
  }
  .nav-center-header {
    .item-menu {
      color: #fff !important;
      &:hover {
        color: #13a81b !important;
      }
    }
  }
}
</style>
